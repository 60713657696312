const CART_STORAGE_KEY = 'shopping-cart';

class CartService {
    constructor() {
        this.cart = this.loadCart();
        this.initializeUI();
    }

    initializeUI() {
        const cartButton = document.getElementById('cart-button');
        const miniCart = document.getElementById('mini-cart');

        if (cartButton && miniCart) {
            cartButton.addEventListener('mouseenter', () => {
                miniCart.style.display = 'block';
            });

            miniCart.addEventListener('mouseleave', () => {
                miniCart.style.display = 'none';
            });
        }
    }

    loadCart() {
        const savedCart = localStorage.getItem(CART_STORAGE_KEY);
        return savedCart ? JSON.parse(savedCart) : [];
    }

    saveCart() {
        localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(this.cart));
        this.updateCartUI();
    }

    addItem(product, quantity = 1) {
        const existingItem = this.cart.find(item => item.id === product.id);
        
        if (existingItem) {
            existingItem.quantity += quantity;
        } else {
            this.cart.push({
                id: product.id,
                name: product.name,
                price: product.price,
                image: product.image || product.images?.main,
                quantity: quantity
            });
        }
        
        this.saveCart();
    }

    removeItem(productId) {
        this.cart = this.cart.filter(item => item.id !== productId);
        this.saveCart();
    }

    updateQuantity(productId, quantity) {
        const item = this.cart.find(item => item.id === productId);
        if (item) {
            item.quantity = quantity;
            this.saveCart();
        }
    }

    getTotal() {
        return this.cart.reduce((total, item) => total + (item.price * item.quantity), 0);
    }

    getItemCount() {
        return this.cart.reduce((count, item) => count + item.quantity, 0);
    }

    updateCartUI() {
        const cartCount = document.getElementById('cart-count');
        const count = this.getItemCount();
        
        if (cartCount) {
            cartCount.textContent = count;
            if (count > 0) {
                cartCount.style.display = 'flex';
                this.animateCartCount(cartCount);
            } else {
                cartCount.style.display = 'none';
            }
        }

        this.updateMiniCartUI();
    }

    animateCartCount(element) {
        element.classList.add('cart-count-animation');
        setTimeout(() => {
            element.classList.remove('cart-count-animation');
        }, 300);
    }

    updateMiniCartUI() {
        const miniCartItems = document.getElementById('mini-cart-items');
        const miniCartTotal = document.getElementById('mini-cart-total');
        
        if (miniCartItems) {
            if (this.cart.length === 0) {
                miniCartItems.innerHTML = '<p class="mini-cart-empty">Your cart is empty</p>';
                return;
            }

            miniCartItems.innerHTML = this.cart.map(item => `
                <div class="mini-cart-item">
                    <div class="mini-cart-item-image">
                        <img src="${item.image}" alt="${item.name}">
                    </div>
                    <div class="mini-cart-item-details">
                        <h4>${item.name}</h4>
                        <p>${item.quantity} × $${item.price.toFixed(2)}</p>
                    </div>
                </div>
            `).join('');
        }
        
        if (miniCartTotal) {
            miniCartTotal.textContent = `$${this.getTotal().toFixed(2)}`;
        }
    }
}

window.cartService = new CartService();